import React, { useCallback, useRef, useEffect, useState, useMemo, startTransition } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StandardField from './StandardField';
import SelectInputField from './SelectInputField'
import { useMemoCompare } from '../../../hooks/hooks'
import { useDrop } from 'react-dnd'
import segmentTemplatesStructure from '../../../utils/segmentTemplates'

const PREFIX = 'MultiQuestionField';

const classes = {
  root: `${PREFIX}-root`,
  dropzone: `${PREFIX}-dropzone`,
  addbutton: `${PREFIX}-addbutton`
};

const QuestionDropRoot = styled('div')({
  width: '95%',
  height: '6px',
  margin: '2px auto 2px auto',
  opacity: '0.3'
});

const Root = styled('div')({
  width: '100%',
  [`& .${classes.addbutton}`]: {
    marginTop: '8px'
  }
});

const isEqual = require('lodash/isEqual');
const cloneDeep = require('lodash/cloneDeep');

const QuestionDropTarget = React.memo(({ onDrop }) => {

  const [{ isOver }, drop] = useDrop({
    accept: 'question',
    drop: (item) => onDrop(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })
  return <QuestionDropRoot style={{ backgroundColor: isOver ? 'gold' : 'transparent' }} className={classes.dropzone} ref={drop} />;
})

const MultiQuestionField = React.memo(({ value, label, keyLabel, fieldKey, disabled, required, conditional, conditionMet, onChange, invalid, invalidate, showGoals, showTerminate, ...restProps }) => {
  // console.log('render MultiQuestionField')
  const valueRef = useRef();
  const keyRef = useRef();
  const [inputVal, setInputVal] = useState();

  const [slectedSegmentTemplate, setSelectedSegmentTemplate] = useState("")
  const [showSegmentTemplateOptions, setShowSegmentTemplateOptions] = useState(false)
  const segmentTemplateOptions = useMemo(() => segmentTemplatesStructure.map(item => item.questionKey), []);

  valueRef.current = value || []

  useEffect(() => {
    setInputVal(inputVal ?? value)
  }, [inputVal, value])

  const handleOnChange = useCallback((a, b) => {
    setInputVal(b ?? value)
    startTransition(() => {
      onChange(a, b);
    })
  }, [value, onChange]);

  //console.log('QUESTIon builder', showTerminate)

  const questionKeys = useMemoCompare(value ? value.map(x => (x.matrix ? (x.matrixKeys || []).map(option => ({
    key: x.questionKey,
    option: option.option,
    display: `${x.questionKey} \u2011 ${option}`
  })) : {
    key: x.questionKey,
    display: x.questionKey
  })).flat().filter(x => !!x) : [], isEqual);

  useEffect(() => {
    if (!isEqual(keyRef.current, questionKeys)) {
      const newQuestions = cloneDeep(valueRef.current).map((q) => {
        if (!q.crosstabKeys || q.crosstabKeys.filter(crosstabKey => !questionKeys.find(x => x.key === crosstabKey.key && (crosstabKey.option ? x.option === crosstabKey.option : true))).length) q.crosstabKeys = []
        return q
      })
      handleOnChange(null, newQuestions)
    }
    keyRef.current = questionKeys
  }, [questionKeys, handleOnChange])

  const handleAddQuestion = useCallback(() => {
    const newQuestion = {
      question: '',
      multiple: false,
      excluded: false,
      hasCrosstab: false,
      crosstabKeys: [],
      freeNumeric: false,
      answers: [{}],
    }
    const newValue = [...valueRef.current, newQuestion]
    handleOnChange(null, newValue)
  }, [handleOnChange])

  const handleExpandAll = useCallback(() => {
    let upd = valueRef.current.map(i => ({ ...i, collapsed: false }));
    handleOnChange(null, upd)
  }, [handleOnChange])

  const handleCollapseAll = useCallback(() => {
    let upd = valueRef.current.map(i => ({ ...i, collapsed: true }));
    handleOnChange(null, upd)
  }, [handleOnChange])

  const handleQuestionChange = useCallback((event, newValue, questionIndex) => {
    const newQuestions = cloneDeep(valueRef.current)
    newQuestions[questionIndex] = newValue
    // console.log('NEW QUESTION VALUE IS', JSON.stringify(newValue), questionIndex)
    handleOnChange(null, newQuestions)
  }, [handleOnChange])

  const handleRemoveQuestion = useCallback((index) => {
    const newQuestions = cloneDeep(valueRef.current)
    newQuestions.splice(index, 1)
    invalidate && invalidate(`${fieldKey}-question${index}`, false)
    handleOnChange(null, newQuestions)
  }, [handleOnChange, invalidate, fieldKey])

  const handleReorderQuestion = useCallback((item, index) => {
    //console.log("handleReorderQuestion", item, index);
    const newQuestions = cloneDeep(valueRef.current)
    const x = newQuestions[item.index]
    newQuestions.splice(item.index, 1)
    newQuestions.splice(index, 0, x)
    handleOnChange(null, newQuestions)
  }, [handleOnChange])

  const handleSegmentTemplateOption = useCallback((event, value) => {
    setSelectedSegmentTemplate("")
    setShowSegmentTemplateOptions(false)
    // Find the template object from the segment template
    const selectedTemplate = segmentTemplatesStructure.find(item => item.questionKey === value)
    // Add sample template
    const newSegment = [...valueRef.current, selectedTemplate]
    handleOnChange(null, newSegment)
  }, [handleOnChange])

  return (
    <Root>
      {label && <Typography className={classes.label}>{label}</Typography>}
      {(inputVal && inputVal.length > 0) && <><Button variant="outlined" onClick={handleExpandAll}>
        Expand All
      </Button>{'\u0020'}
        <Button variant="outlined" onClick={handleCollapseAll}>
          Collapse All
        </Button></>}
      {inputVal && inputVal.map((question, i) =>
        <React.Fragment key={`questionfragment${i}`}>
          {(!disabled && value.length > 1) && <QuestionDropTarget key={`questiondrop${i}`} onDrop={(item) => handleReorderQuestion(item, i)} />}
          {(disabled || value.length === 1) && <div className={classes.dropzone} />}
          <StandardField
            type='question'
            fieldKey={`${fieldKey}-question${i}`}
            key={`${fieldKey}-question${i}`}
            questionKey={`${fieldKey}-question${i}`}
            questionKeys={questionKeys}
            index={i}
            value={question}
            required={true}
            // required={required || (conditional && conditionMet)}
            disabled={disabled}
            showTerminate={showTerminate}
            showGoals={showGoals}
            onRemove={handleRemoveQuestion}
            onChange={handleQuestionChange}
            draggable={!disabled}
            invalidate={invalidate}
            label={keyLabel}
            {...restProps}
          />
          {(!disabled && (value.length > 1 && i === value.length - 1)) && <QuestionDropTarget key={`questiondrop${value.length}`} onDrop={(item) => handleReorderQuestion(item, i)} />}
          {(disabled || value.length === 1) && <div className={classes.dropzone} />}
        </React.Fragment>
      )}
      <div className={classes.addbutton}>
        <Button variant="outlined" disabled={disabled} onClick={handleAddQuestion}>
          <AddIcon /> Add Segment Question
        </Button>
        <Button variant="outlined" disabled={disabled} onClick={() => setShowSegmentTemplateOptions(!showSegmentTemplateOptions)} style={{ marginLeft: "4px" }}>
          <AddIcon /> Select Segments Template
        </Button>

        {
          showSegmentTemplateOptions &&
          <div>
            <SelectInputField options={segmentTemplateOptions} optionLabel="display" optionValueKey="display" label="" placeholder={'Select Segments Template'} disabled={disabled} multiple={false} filterSelected={true} fullObjects={false} value={slectedSegmentTemplate} onChange={handleSegmentTemplateOption} />
          </div>
        }
      </div>
    </Root>
  );
})

MultiQuestionField.defaultProps = {

}

export default MultiQuestionField;
