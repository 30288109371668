import React, { useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Collapse, IconButton, Checkbox, Typography, TextField, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SelectInputField from './SelectInputField'
import CheckboxField from './CheckboxField';

const PREFIX = 'ChatChannelField';

const classes = {
  root: `${PREFIX}-root`,
  box: `${PREFIX}-box`,
  addbutton: `${PREFIX}-addbutton`,
  answers: `${PREFIX}-answers`,
  checkbox: `${PREFIX}-checkbox`,
  checkboxPadding: `${PREFIX}-checkboxPadding`,
  checkboxContent: `${PREFIX}-checkboxContent`,
  checkLabel: `${PREFIX}-checkLabel`,
  label: `${PREFIX}-label`,
  fieldLabel: `${PREFIX}-fieldLabel`,
  answerrow: `${PREFIX}-answerrow`,
  textField: `${PREFIX}-textField`,
  keyInput: `${PREFIX}-keyInput`,
  shapeInput: `${PREFIX}-shapeInput`,
  timeField: `${PREFIX}-timeField`,
  colon: `${PREFIX}-colon`,
  plus: `${PREFIX}-plus`,
  templates: `${PREFIX}-templates`
};

const Root = styled('div')({
  // style for <Root> component
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  padding: '6px',
  margin: '12px 8px 12px 8px',
  border: '2px solid #eee',
  borderRadius: '8px',
  transform: 'translate3d(0, 0, 0)',
  [`& .${classes.box}`]: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
  },
  [`& .${classes.addbutton}`]: {
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  [`& .${classes.answers}`]: {
    padding: '4px',
    width: '100%',
    borderLeft: '2px solid #eee'
  },
  [`& .${classes.checkbox}`]: {
    flexBasis: '50%',
    // textAlign: 'right',
    // marginRight: '10px'
  },
  [`& .${classes.checkboxPadding}`]: {
    padding: '4px',
  },
  [`& .${classes.checkboxContent}`]: {
    display: 'flex',
    alignItems: 'center',
    width: 'max-content'
  },
  [`& .${classes.checkLabel}`]: {
    // width: '100%'
  },
  [`& .${classes.label}`]: {
    textAlign: 'right',
    marginRight: '10px !important'
  },
  [`& .${classes.fieldLabel}`]: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px !important',
  },
  [`& .${classes.answerrow}`]: {
    display: 'flex'
  },
  [`& .${classes.textField}`]: {
    flex: 1,
    padding: '4px'
  },
  [`& .${classes.keyInput}`]: {
    // width: '3.5em',
    width: '20%',
    padding: '4px'
  },
  [`& .${classes.shapeInput}`]: {
    // width: '3.5em',
    // width: '2rem',
    // padding: '4px'
  },
  [`& .${classes.timeField}`]: {
    // width: '3.5em',
    width: '20%',
    padding: '4px'
  },
  [`& .${classes.colon}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px'
  },
  [`& .${classes.plus}`]: {
    margin: '2px'
  },
  [`& .${classes.templates}`]: {
    display: 'flex',
    flexBasis: '50%',
  },
});

const userAccessTypes = {
  group: 'Group/Role-Based',
  private: 'Individual Assignment',
}

const userRoleOptions = [
  'Client'
]

const staffRoleOptions = [
  {
    name: 'Session Level',
    id: 'session'
  },
  {
    name: 'Audience Level',
    id: 'audience'
  },
  {
    name: 'Tech Check Level',
    id: 'tc'
  },
  {
    name: 'Balance Level',
    id: 'balance'
  },
  {
    name: 'Client Interface Level',
    id: 'client'
  },
  {
    name: 'Live Dial Level',
    id: 'dial'
  },
  {
    name: 'Video Mangement Level',
    id: 'video'
  }
]

const ChatChannelField = React.memo(({ value, disabled, placeholder, onChange, onRemove }) => {


  const valueRef = useRef();
  const handleUserAccessRef = useRef();
  valueRef.current = value

  const handleNameChange = useCallback((event) => {
    const newChannel = { ...valueRef.current }
    newChannel.name = event.target.value
    onChange(event, newChannel)
  }, [onChange]);

  const handleInternalChange = useCallback((event) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.internal = event.target.checked
    newChannel.type = 'group'
    newChannel.userAccess = []
    onChange(event, newChannel)
  }, [onChange]);

  const handleUserAccessTypeChange = useCallback((event) => {
    // event.currentTarget.blur()
    handleUserAccessRef.current.node.blur();
    const newChannel = { ...valueRef.current }
    newChannel.type = event.target.value
    onChange(event, newChannel)
  }, [onChange]);

  const handleUserRoleChange = useCallback((event, newValue) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.userAccess = newValue
    onChange(event, newChannel)
  }, [onChange]);

  const handleStaffAccessChange = useCallback((event, newValue) => {
    event.currentTarget.blur()
    const newChannel = { ...valueRef.current }
    newChannel.staffAccess = newValue.map(x => x.id)
    onChange(event, newChannel)
  }, [onChange]);

  const handleRestrictedChange = useCallback((event, newValue) => {
    const newChannel = { ...valueRef.current }
    newChannel.restricted = newValue
    onChange(event, newChannel)
  }, [onChange]);

  return (
    <Root>
      <div className={classes.box}>
        <TextField id={'channel-name-text-field'} className={classes.textField} variant="outlined" size="small" placeholder={placeholder || 'Enter Channel Name'} error={!value.name} disabled={disabled} value={value.name || ''} onChange={handleNameChange} />
        <div className={classes.checkboxPadding}>
          <div className={classes.checkboxContent}>
            <Checkbox size="small" color="primary" checked={value.internal} disabled={disabled || value.default} onChange={handleInternalChange} />
            <Typography className={classes.checkLabel}>Staff-Only Channel</Typography>
          </div>
        </div>
        <IconButton disabled={disabled || value.default} onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={classes.box}>
        <SelectInputField label="Staff Access Levels" options={staffRoleOptions} optionLabel='name' optionValueKey='id' placeholder={placeholder || 'Select Allowed Staff Access Levels'} disabled={disabled || value.default} fixedOptions={['session']} multiple={true} filterSelected={true} value={value.staffAccess} onChange={handleStaffAccessChange} />
      </div>
      <Collapse in={!value.internal}>
        <div className={classes.box}>
          <Typography className={classes.fieldLabel}>Allow Access To:</Typography>
          <TextField
            id={'user-access-type-text-field'}
            className={classes.textField}
            inputRef={handleUserAccessRef}
            value={value.type || ''}
            onChange={handleUserAccessTypeChange}
            variant="outlined"
            size="small"
            placeholder={placeholder || 'Select User Access Type'}
            disabled={disabled || value.default}
            error={!value.type}
            select
          >
            {Object.entries(userAccessTypes).map(([type, label]) => (
              <MenuItem key={`accesstype${type}`} value={type}>{label}</MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.box}>
          <SelectInputField label="User Roles" options={userRoleOptions} placeholder={placeholder || 'Select Allowed User Roles'} disabled={disabled || value.default} multiple={true} filterSelected={true} value={value.userAccess} onChange={handleUserRoleChange} />
        </div>
        <Collapse in={value.type === 'private'}>
          <div className={classes.box}>
            {/* <div className={classes.checkboxContent}>
              <Checkbox size="small" color="primary" checked={value.restricted} disabled={disabled || value.default} onChange={handleRestrictedChange} />
              <Typography className={classes.checkLabel}>Restrict Message Viewing</Typography>
            </div> */}
            <CheckboxField label="Restrict Message Viewing to Users" value={value.restricted} disabled={disabled || value.default} onChange={handleRestrictedChange} />
          </div>
        </Collapse>
      </Collapse>
    </Root>
  );
})

// ChatChannelField.defaultProps = {
//   label: ''
// }

export default ChatChannelField;
