import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material';
const PREFIX = 'MinuteInputField';

const classes = {
  root: `${PREFIX}-root`,
  marker: `${PREFIX}-marker`,
  label: `${PREFIX}-label`,
  timeField: `${PREFIX}-timeField`,
  text: `${PREFIX}-text`
};

const Root = styled('div')({
  //border: 'solid 2px green',
  display: 'flex',
  padding: '5px',
  alignItems: 'center',
  // width: '100%'
  [`& .${classes.marker}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    flexBasis: '80%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.timeField}`]: {
    width: '3.5em'
  },
  [`& .${classes.text}`]: {
    padding: '3px'
  },
});

let intg = /[0-9]+/

const MinuteInputField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalid, invalidate, onRemove }) => {


  console.log('minute render', value)
  console.log('placeholder', placeholder)

  const handleValueChange = useCallback((event) => {
    const newValue = event.target.value
    if (intg.test(newValue) || newValue === '') {
      onChange(null, newValue === '' ? null : parseInt(newValue))
    }
  }, [onChange]);

  return (
    <Root>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.marker}>
        <TextField id={`minute-input-text-field-${label}`} className={classes.timeField} placeholder={placeholder || ''} inputProps={{ maxLength: 2, inputMode: "numeric" }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value} onChange={handleValueChange} />
        <Typography className={classes.text}>minutes</Typography>
      </div>
    </Root>
  );
})

MinuteInputField.defaultProps = {
  label: 'Minutes:'
}

export default MinuteInputField;
