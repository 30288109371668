import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Collapse, IconButton, Checkbox, Typography, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDrag, useDrop } from 'react-dnd'
import SelectInputField from './SelectInputField'
import ChipInputField from './ChipInputField';

const PREFIX = 'QuestionField';

const classes = {
  root: `${PREFIX}-root`,
  box: `${PREFIX}-box`,
  addbutton: `${PREFIX}-addbutton`,
  answers: `${PREFIX}-answers`,
  checkbox: `${PREFIX}-checkbox`,
  checkLabel: `${PREFIX}-checkLabel`,
  label: `${PREFIX}-label`,
  questionLabel: `${PREFIX}-questionLabel`,
  answerLabel: `${PREFIX}-answerLabel`,
  answerRow: `${PREFIX}-answerRow`,
  optionLabel: `${PREFIX}-optionLabel`,
  answerInput: `${PREFIX}-answerInput`,
  keyField: `${PREFIX}-keyField`,
  displayField: `${PREFIX}-displayField`,
  textField: `${PREFIX}-textField`,
  timeField: `${PREFIX}-timeField`,
  colon: `${PREFIX}-colon`,
  plus: `${PREFIX}-plus`,
  move: `${PREFIX}-move`,
  moveIcon: `${PREFIX}-moveIcon`,
  dropzone: `${PREFIX}-dropzone`,
  multipleOptionBox: `${PREFIX}-multipleOptionBox`,
  fullWidthInput: `${PREFIX}-fullWidthInput`,
  optionBoxHeight: `${PREFIX}-optionBoxHeight`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: '6px',
    margin: '2px 8px 2px 8px',
    border: '2px solid #eee',
    borderRadius: '8px',
    transform: 'translate3d(0, 0, 0)'
  },
  [`& .${classes.box}`]: {
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
  },
  [`& .${classes.addbutton}`]: {
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  [`& .${classes.answers}`]: {
    padding: '4px',
    width: '100%',
    borderLeft: '2px solid #eee'
  },
  [`& .${classes.checkbox}`]: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.checkLabel}`]: {
    width: '100%'
  },
  [`& .${classes.label}`]: {
    textAlign: 'right',
    marginRight: '10px !important'
  },
  [`& .${classes.questionLabel}`]: {
    flexBasis: '20%',
    textAlign: 'right',
    marginRight: '10px !important',
  },
  [`& .${classes.answerLabel}`]: {
    flexBasis: '10%',
    textAlign: 'right',
    marginRight: '10px !important',
    padding: '5px',
  },
  [`& .${classes.answerRow}`]: {
    display: 'flex',
    alignItems: 'center'
  },
  [`& .${classes.optionLabel}`]: {
    flexBasis: '40%',
    textAlign: 'right',
    marginRight: '10px !important',
    padding: '5px',
  },
  [`& .${classes.answerInput}`]: {
    minHeight: '15px'
  },
  [`& .${classes.keyField}`]: {
    width: '40%',
    padding: '4px'
  },
  [`& .${classes.displayField}`]: {
    width: '50%',
    padding: '4px'
  },
  [`& .${classes.textField}`]: {
    width: '80%',
    padding: '4px'
  },
  [`& .${classes.timeField}`]: {
    // width: '3.5em',
    width: '20%',
    padding: '4px'
  },
  [`& .${classes.colon}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px'
  },
  [`& .${classes.plus}`]: {
    margin: '2px'
  },
  [`& .${classes.move}`]: {
    cursor: 'move',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '12px',
    fontSize: '1.5rem',
    textAlign: 'center'
  },
  [`& .${classes.moveIcon}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit'
  },
  [`& .${classes.dropzone}`]: {
    width: '100%',
    height: '2px',
    margin: '0px auto 0px auto',
    opacity: '0.3'
  },
  [`& .${classes.multipleOptionBox}`]: {
    marginBottom: '5px',
    marginTop: "5px",
    minHeight: "25px"
  },
  [`& .${classes.fullWidthInput}`]: {
    width: '89%',
    margin: 'auto',
  },
  [`& .${classes.optionBoxHeight}`]: {
    minHeight: "100px !important"
  }
});

const cloneDeep = require('lodash/cloneDeep');

const AnswerDropTarget = React.memo(({ onDrop }) => {

  const [{ isOver, isDragging }, drop] = useDrop({
    accept: 'answer',
    drop: (item) => onDrop(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return <div style={{ backgroundColor: isOver ? 'black' : 'transparent' }} className={classes.dropzone} ref={drop} />
})

const OptionDropTarget = React.memo(({ onDrop }) => {

  const [{ isOver, isDragging }, drop] = useDrop({
    accept: 'option',
    drop: (item) => onDrop(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  return <div style={{ backgroundColor: isOver ? 'black' : 'transparent' }} className={classes.dropzone} ref={drop} />
})

const QuestionField = React.memo(({ value, label, disabled, placeholder, fieldKey, onChange, invalid, invalidate, showTerminate, showGoals, questionPlaceholder, answerPlaceholder, questionKeys, onRemove, draggable, index }) => {

  const valueRef = useRef(value);

  const questionKey = value?.questionKey

  useEffect(() => {
    valueRef.current = value
  }, [value])

  const [showMultipleOptionsFields, setShowMultipleOptionsFields] = useState(false)

  // console.log('question key is', fieldKey, value.question, showTerminate)

  const invalidAnswers = useMemo(() => {
    return new Set(value.answers.map(x => x.answer).filter((s => v => s.has(v) || !s.add(v))(new Set())))
  }, [value])

  const invalidOption = useMemo(() => {
    if (!Array.isArray(value?.matrixKeys)) {
      return null;
    }

    return new Set(value?.matrixKeys.map(x => x.option).filter((s => v => s.has(v) || !s.add(v))(new Set())))
  }, [value])

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'question',
    item: { index },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const handleCollapse = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.collapsed = !(!!valueRef.current.collapsed)
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleKeyChange = useCallback((event) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.questionKey = event.target.value.replace(/[:\.]/g, '').replace(/\s{2,}/g, ' ').replace(/^\s*/, '')
    if (newQuestion.matrix) {
      const len = 40 - (newQuestion.questionKey || '').length
      for (let i in (newQuestion.matrixKeys || [])) {
        if ((newQuestion.matrixKeys[i].option || '').length > len) {
          const newOption = { ...newQuestion.matrixKeys[i] }
          newOption.option = newOption.option.slice(0, len)
          newQuestion.matrixKeys[i] = newOption
          // console.log('NEW OPTION!', newOption)
        }
      }
    }
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleQuestionChange = useCallback((event) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.question = event.target.value
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleCheckChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.multiple = event.target.checked
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleNumericChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.freeNumeric = event.target.checked
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleMatrixChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.matrix = event.target.checked
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.matrixKeys = [{}]
    for (let i in newQuestion.answers) newQuestion.answers[i].goal = event.target.checked ? {} : null
    // if (!event.target.checked) {
    //   newQuestion.matrixKeys = []
    // }
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleAddMatrixKey = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.matrixKeys = cloneDeep(valueRef.current.matrixKeys)
    newQuestion.matrixKeys.push({})
    onChange(event, newQuestion, index)
  }, [onChange, index])

  const handleRemoveMatrixKey = useCallback((event, i) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.matrixKeys = cloneDeep(valueRef.current.matrixKeys)
    newQuestion.matrixKeys.splice(i, 1)
    onChange(event, newQuestion, index)
  }, [onChange, index])

  const handleOptionBlur = useCallback((event, i) => {
    const newOptions = event.target.value.replace(/\s{2,}/g, ' ').replace(/^\s*/, '').replace(/\r\n/g, /\n/).replace(/\r/g, /\n/).split(/\n/).map(ans => {
      const [option, display] = ans.split('|')
      return { option, display }
    })
    if (newOptions.length > 1) {
      const newQuestion = { ...valueRef.current }
      newQuestion.matrixKeys = cloneDeep(valueRef.current.matrixKeys)
      newQuestion.matrixKeys.splice(i, 1, ...newOptions)
      onChange(event, newQuestion, index)
    }
  }, [onChange, index])

  const handleOptionsBlur = useCallback((options) => {
    const newOptions = options.replace(/\s{2,}/g, ' ').replace(/^\s*/, '').replace(/\r\n/g, /\n/).replace(/\r/g, /\n/).split(/\n/).map(ans => {
      const [option, display] = ans.split('|')
      return { option, display }
    })
    if (newOptions.length > 1) {
      // Hide option box
      setShowMultipleOptionsFields(false)
      const newQuestion = { ...valueRef.current }
      newQuestion.matrixKeys = cloneDeep(valueRef.current.matrixKeys)
      // Add new options to the end of the array
      newQuestion.matrixKeys.splice(newQuestion.matrixKeys.length, 0, ...newOptions)

      // Remove the newQuestion.matrixKeys which have no option key
      newQuestion.matrixKeys = newQuestion.matrixKeys.filter(key => key.option)

      // Remove the duplicate options
      newQuestion.matrixKeys = newQuestion.matrixKeys.filter((key, index, self) => self.findIndex(k => k.option === key.option) === index)
      onChange(null, newQuestion, index)
    }
  }, [onChange, index])

  const handleMatrixKeyChange = useCallback((event, i, displayText) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.matrixKeys = cloneDeep(valueRef.current.matrixKeys)
    newQuestion.matrixKeys[i][displayText ? 'display' : 'option'] = event.target.value.replace(/[:\.]/g, '').replace(/\s{2,}/g, ' ').replace(/^\s*/, '')
    onChange(event, newQuestion, index)
  }, [onChange, index])

  const handleMatrixKeysChange = useCallback((event, newValue) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.matrixKeys = [...new Set(newValue || [])]
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleExcludedChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.excluded = event.target.checked
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleQuotaChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.quota = event.target.checked
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleCrosstabChange = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.hasCrosstab = event.target.checked
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleCrosstabKeyChange = useCallback((event, newValue) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.crosstabKeys = newValue
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleAddAnswer = useCallback((event) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers.push({})
    onChange(event, newQuestion, index)
  }, [onChange, index])

  const handleRemoveAnswer = useCallback((event, i) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers.splice(i, 1)
    onChange(event, newQuestion, index)
  }, [onChange, index])

  const handleAnswerChange = useCallback((event, i, displayText) => {
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i][displayText ? 'display' : 'answer'] = event.target.value.replace(/\s{2,}/g, ' ').replace(/^\s*/, '')
    onChange(event, newQuestion, index)
  }, [onChange, index])

  const handleAnswerBlur = useCallback((event, i, displayText) => {
    const newAnswers = event.target.value.replace(/\s{2,}/g, ' ').replace(/^\s*/, '').replace(/\r\n/g, /\n/).replace(/\r/g, /\n/).split(/\n/).map(ans => {
      const [answer, display] = ans.split('|')
      return { answer, display }
    })
    if (newAnswers.length > 1) {
      const newQuestion = { ...valueRef.current }
      newQuestion.answers = cloneDeep(valueRef.current.answers)
      newQuestion.answers.splice(i, 1, ...newAnswers)
      onChange(event, newQuestion, index)
    }
  }, [onChange, index])

  const handleGoalChange = useCallback((event, i, option) => {
    const newValue = event.target.value
    if (/[0-9]+/.test(newValue) || !newValue) {
      const newQuestion = { ...valueRef.current }
      newQuestion.answers = cloneDeep(valueRef.current.answers)
      if (option?.option) {
        newQuestion.answers[i].goal = {
          ...(newQuestion.answers[i].goal || {}),
          [option.option]: newValue
        }
      } else {
        newQuestion.answers[i].goal = newValue
      }
      onChange(event, newQuestion, index)
    }
  }, [onChange, index]);

  const handleTerminateChange = useCallback((event, i) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].terminate = event.target.checked
    if (!event.target.checked) newQuestion.answers[i].terminateOptions = undefined
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleTerminateOptionsChange = useCallback((event, newValue, i) => {
    event.currentTarget.blur()
    const newQuestion = { ...valueRef.current }
    newQuestion.answers = cloneDeep(valueRef.current.answers)
    newQuestion.answers[i].terminateOptions = newValue
    onChange(event, newQuestion, index)
  }, [onChange, index]);

  const handleQuestionRemove = useCallback(() => {
    onRemove(index)
  }, [onRemove, index])

  const maxOptionLength = useMemo(() => {
    return (40 - (questionKey || '').length)
  }, [questionKey])

  // console.log('MATRIX KEYS', value.matrixKeys)

  const handleDrop = useCallback((item, i) => {

    //console.log("drag", item, i);
    // Update answers position
    const newAnswers = cloneDeep(valueRef.current.answers)
    const x = newAnswers[item.i]
    newAnswers.splice(item.i, 1)
    newAnswers.splice(i, 0, x)

    const newQuestion = { ...valueRef.current }
    newQuestion.answers = newAnswers

    //console.log("newQuestion", newQuestion.answers);
    onChange(null, newQuestion, index)

  }, [onChange, index])

  const handleOptionDrop = useCallback((item, i) => {

    // Update option position
    const newMatrix = cloneDeep(valueRef.current.matrixKeys)
    const x = newMatrix[item.i]
    newMatrix.splice(item.i, 1)
    newMatrix.splice(i, 0, x)

    const newQuestion = { ...valueRef.current }
    newQuestion.matrixKeys = newMatrix

    //console.log("newQuestion", newQuestion.answers);
    onChange(null, newQuestion, index)

  }, [onChange, index])

  return (
    <Root>
      <div className={classes.root} ref={preview} style={{
        opacity: isDragging ? 0.5 : 1,
        // cursor: draggable ? 'move' : 'auto',
      }}>
        <QuestionKeyInput key={`${fieldKey}questionkey`} label={label} placeholder={placeholder} disabled={disabled} questionKey={value.questionKey} handleKeyChange={handleKeyChange} collapsed={value.collapsed} handleCollapse={handleCollapse} onRemove={handleQuestionRemove} draggable={draggable} drag={drag} />
        <Collapse key={`${fieldKey}collapse`} in={!(!!value.collapsed)}>
          <QuestionInput fieldkey={`${fieldKey}question`} questionKey={value.questionKey} questionKeys={questionKeys} placeholder={questionPlaceholder} disabled={disabled} question={value.question} multiple={value.multiple} freeNumeric={value.freeNumeric} matrix={value.matrix} matrixKeys={value.matrixKeys} excluded={value.excluded} quota={value.quota} hasCrosstab={value.hasCrosstab} crosstabKeys={value.crosstabKeys} handleQuestionChange={handleQuestionChange} handleCheckChange={handleCheckChange} handleNumericChange={handleNumericChange} handleMatrixChange={handleMatrixChange} handleMatrixKeysChange={handleMatrixKeysChange} handleExcludedChange={handleExcludedChange} handleQuotaChange={handleQuotaChange} handleCrosstabChange={handleCrosstabChange} handleCrosstabKeyChange={handleCrosstabKeyChange} />
          <Collapse key={`${fieldKey}matrixoptions`} in={!!value.matrix}>
            <div className={classes.box}>
              <Typography className={classes.answerLabel}>Matrix Options</Typography>
              <div className={classes.answers}>
                {value.matrixKeys?.map((option, i) => (
                  <React.Fragment key={`${fieldKey}option${i}`}>
                    {(value.matrixKeys.length > 1 && i === 0) && <OptionDropTarget key={`optiondrop${i}`} onDrop={(item) => handleOptionDrop(item, i)} />}
                    <OptionInput key={`${fieldKey}option${i}`} disabled={disabled} fieldKey={fieldKey} invalid={invalidOption.has(option.option)} option={option.option} display={option.display} i={i} maxLength={maxOptionLength} handleOptionChange={handleMatrixKeyChange} handleRemoveOption={handleRemoveMatrixKey} handleOptionBlur={handleOptionBlur} />
                    {(value.matrixKeys.length > 1) && <OptionDropTarget key={`optiondrop${value.answers.length}`} onDrop={(item) => handleOptionDrop(item, i)} />}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className={classes.addbutton}>
              <Button onClick={() => setShowMultipleOptionsFields(!showMultipleOptionsFields)}>
                <AddIcon className={classes.plus} /> Add Multiple Options
              </Button>
              <Button disabled={disabled} onClick={handleAddMatrixKey}>
                <AddIcon className={classes.plus} /> Add Option
              </Button>
            </div>
            {
              showMultipleOptionsFields && (
                <div className={[classes.box, classes.multipleOptionBox].join(" ")}>
                  <div className={classes.answerLabel} />
                  <div className={classes.answers}>
                    <OptionsInput handleBlur={handleOptionsBlur} />
                  </div>
                </div>
              )
            }
          </Collapse>
          <div className={classes.box}>
            <Typography className={classes.answerLabel}>{value.freeNumeric ? 'Answer Groups' : 'Answers'}</Typography>
            <div className={classes.answers}>
              {value.answers.map((answer, i) => (
                <React.Fragment key={`${fieldKey}answer${i}`}>
                  {(value.answers.length > 1 && i === 0) && <AnswerDropTarget key={`answerdrop${i}`} onDrop={(item) => handleDrop(item, i)} />}
                  <AnswerInput key={`${fieldKey}answer${i}`} forGroup={!!value.freeNumeric} fieldKey={fieldKey} disabled={disabled} answerPlaceholder={answerPlaceholder} answer={answer.answer} display={answer.display} i={i} invalid={invalidAnswers.has(answer.answer)} handleAnswerChange={handleAnswerChange} handleAnswerBlur={handleAnswerBlur} goal={answer.goal} handleGoalChange={handleGoalChange} matrix={value.matrix} matrixKeys={value.matrixKeys} showGoals={showGoals} showTerminate={showTerminate} terminate={answer.terminate} terminateOptions={answer.terminateOptions} handleTerminateChange={handleTerminateChange} handleTerminateOptionsChange={handleTerminateOptionsChange} handleRemoveAnswer={handleRemoveAnswer} />
                  {(value.answers.length > 1) && <AnswerDropTarget key={`answerdrop${value.answers.length}`} onDrop={(item) => handleDrop(item, i)} />}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className={classes.addbutton}>
            <Button disabled={disabled} onClick={handleAddAnswer}>
              <AddIcon className={classes.plus} /> Add Answer
            </Button>
          </div>
        </Collapse>
      </div>
    </Root>
  );
})

const QuestionKeyInput = React.memo(({ disabled, questionKey, handleKeyChange, collapsed, handleCollapse, label, placeholder, onRemove, draggable, drag }) => {
  // console.log('render question key input', questionKey)

  return (
    <Root>
      <div className={classes.box}>
        {draggable && <div ref={drag} className={classes.move}>
          <OpenWithIcon className={classes.moveIcon} />
        </div>}
        <IconButton onClick={handleCollapse}>
          {collapsed ? <ArrowRightIcon /> : <ArrowDropDownIcon />}
        </IconButton>
        <Typography className={classes.label}>{label}</Typography>
        <TextField id={`question-segnment-${label}`} className={classes.textField} variant="outlined" size="small" inputProps={{ maxLength: 40 }} placeholder={placeholder || 'Enter Segment'} error={!questionKey} disabled={disabled} value={questionKey || ''} onChange={handleKeyChange} />
        <IconButton disabled={disabled} onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Root>
  )
}, (prev, next) => prev.questionKey === next.questionKey && prev.collapsed === next.collapsed)

const QuestionInput = React.memo(({ fieldkey, disabled, question, handleQuestionChange, multiple, handleCheckChange, freeNumeric, handleNumericChange, matrix, matrixKeys = [], handleMatrixChange, handleMatrixKeysChange, excluded, handleExcludedChange, quota, handleQuotaChange, hasCrosstab, crosstabKeys, handleCrosstabChange, handleCrosstabKeyChange, placeholder, questionKey, questionKeys }) => {
  // console.log('render question input', question)

  const filteredKeys = useMemo(() => questionKeys.filter(x => x.key !== questionKey), [questionKey, questionKeys])
  return (
    <Root>
      <div className={classes.box}>
        <Typography className={classes.questionLabel}>Question</Typography>
        <TextField id={`question-segment-question-${fieldkey}`} className={classes.textField} variant="outlined" size="small" placeholder={placeholder || 'Enter Segment Question'} disabled={disabled} value={question || ''} onChange={handleQuestionChange} />
      </div>
      <div className={classes.box}>
        <div className={classes.checkbox}>
          <Checkbox key={`${fieldkey}matrixcheck`} size="small" color="primary" checked={!!matrix} disabled={disabled || multiple || freeNumeric} onChange={handleMatrixChange} />
        </div>
        <Typography className={classes.checkLabel}>Matrix</Typography>
      </div>
      {/* <Collapse in={matrix}>
        <div className={classes.box}>
          <ChipInputField label="Matrix Options" placeholder="Enter Matrix Options" disabled={disabled} value={matrixKeys} onChange={handleMatrixKeysChange} />
        </div>
      </Collapse> */}
      <div className={classes.box}>
        <div className={classes.checkbox}>
          <Checkbox key={`${fieldkey}numericfreecheck`} size="small" color="primary" checked={!!freeNumeric} disabled={disabled || multiple || matrix} onChange={handleNumericChange} />
        </div>
        <Typography className={classes.checkLabel}>Numeric Free Response</Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.checkbox}>
          <Checkbox key={`${fieldkey}multiplecheck`} size="small" color="primary" checked={!!multiple} disabled={disabled || freeNumeric || matrix} onChange={handleCheckChange} />
        </div>
        <Typography className={classes.checkLabel}>Multi-select</Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.checkbox}>
          <Checkbox key={`${fieldkey}excludecheck`} size="small" color="primary" checked={!!excluded} disabled={disabled} onChange={handleExcludedChange} />
        </div>
        <Typography className={classes.checkLabel}>Exclude from Recruit</Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.checkbox}>
          <Checkbox key={`${fieldkey}quotacheck`} size="small" color="primary" checked={!!quota} disabled={disabled} onChange={handleQuotaChange} />
        </div>
        <Typography className={classes.checkLabel}>Limit Registration When Goals Reached</Typography>
      </div>
      <div className={classes.box}>
        <div className={classes.checkbox}>
          <Checkbox key={`${fieldkey}crosstabcheck`} size="small" color="primary" checked={!!hasCrosstab} disabled={disabled} onChange={handleCrosstabChange} />
        </div>
        <Typography className={classes.checkLabel}>Has Crosstab for Balance?</Typography>
      </div>
      <Collapse in={hasCrosstab}>
        <div className={classes.box}>
          <SelectInputField options={filteredKeys} optionLabel="display" optionValueKey="display" label="Crosstab Segments" placeholder={placeholder || 'Select Crosstab Segments'} disabled={disabled} multiple={true} filterSelected={true} fullObjects={true} value={crosstabKeys} onChange={handleCrosstabKeyChange} />
        </div>
      </Collapse>
    </Root>
  )
}, (prev, next) => prev.question === next.question && prev.freeNumeric === next.freeNumeric && prev.matrix === next.matrix && prev.multiple === next.multiple && prev.excluded === next.excluded && prev.quota === next.quota && prev.hasCrosstab === next.hasCrosstab && (Array.isArray(prev.crosstabKeys) && Array.isArray(next.crosstabKeys) && prev.crosstabKeys.length === next.crosstabKeys.length && prev.crosstabKeys.every((val, idx) => val === next.crosstabKeys[idx])) && prev.questionKeys === next.questionKeys && prev.matrixKeys === next.matrixKeys)

const OptionInput = React.memo(({ disabled, optionPlaceholder, option, display, i, invalid, maxLength, handleOptionChange, handleRemoveOption, handleOptionBlur }) => {
  // console.log('render option', option)
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'option',
    item: { i },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })


  return (
    <Root ref={preview} style={{
      opacity: isDragging ? 0.5 : 1,
      // cursor: draggable ? 'move' : 'auto',
    }}>
      <div className={classes.answerRow} ref={drag}>
        <DragIndicatorIcon className={classes.move} />
        <TextField id={`question-matrix-option-${i}`} className={classes.keyField} variant="outlined" size="small" inputProps={{ maxLength }} placeholder={optionPlaceholder || 'Enter Matrix Option'} error={!option || invalid} disabled={disabled} value={option || ''} onChange={(e) => handleOptionChange(e, i)} onBlur={(e) => handleOptionBlur(e, i)} />
        <TextField id={`question-display-text-${i}`} className={classes.displayField} variant="outlined" size="small" placeholder={'Enter Optional Display Text'} disabled={disabled} value={display || ''} onChange={(e) => handleOptionChange(e, i, true)} />
        <IconButton style={{ visibility: i ? 'initial' : 'hidden' }} disabled={disabled} onClick={(e) => handleRemoveOption(e, i)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </Root>
  )
}, (prev, next) => prev.option === next.option && prev.display === next.display && prev.i === next.i && prev.maxLength === next.maxLength && prev.invalid === next.invalid)

const AnswerInput = React.memo(({ disabled, forGroup, answerPlaceholder, goalPlaceholder, answer, display, i, invalid, handleAnswerChange, handleAnswerBlur, matrix, matrixKeys, goal, handleGoalChange, showGoals, showTerminate, terminate, terminateOptions, handleTerminateChange, handleTerminateOptionsChange, handleRemoveAnswer }) => {
  // console.log('render answer', answer, display, goal)

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'answer',
    item: { i },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })


  return (
    <Root ref={preview} style={{
      opacity: isDragging ? 0.5 : 1,
      // cursor: draggable ? 'move' : 'auto',
    }}>
      <div className={classes.answerRow} ref={drag} >
        <DragIndicatorIcon className={classes.move} />
        <TextField id={`question-answer-group-${i}`} className={classes.keyField} variant="outlined" size="small" multiline={true} minRows={1} maxRows={10} InputProps={{ classes: { inputMultiline: classes.answerInput } }} placeholder={answerPlaceholder || `Enter Answer ${forGroup ? 'Group' : 'Option'}`} error={!answer || invalid} disabled={disabled} value={answer || ''} onChange={(e) => handleAnswerChange(e, i)} onBlur={(e) => handleAnswerBlur(e, i)} />
        {!forGroup && <TextField id={`question-answer-display-text-${i}`} className={classes.displayField} variant="outlined" size="small" placeholder={'Enter Optional Display Text'} disabled={disabled} value={display || ''} onChange={(e) => handleAnswerChange(e, i, true)} />}
        {(!matrix && showGoals) && <>
          <TextField id={`question-goal-${i}`} className={classes.timeField} inputProps={{ maxLength: 3, inputMode: "numeric" }} variant="outlined" size="small" placeholder={goalPlaceholder || 'Goal'} disabled={false} value={goal || ''} onChange={(e) => handleGoalChange(e, i)} />
          <Typography className={classes.colon}>%</Typography>
        </>}
        {showTerminate && <div className={classes.answerRow}>
          <Checkbox size="small" color="primary" checked={!!terminate} disabled={disabled} onChange={(e) => handleTerminateChange(e, i)} />
          <Typography className={classes.checkLabel}>Terminate</Typography>
        </div>}
        <IconButton style={{ visibility: i ? 'initial' : 'hidden' }} disabled={disabled} onClick={(e) => handleRemoveAnswer(e, i)}>
          <DeleteIcon />
        </IconButton>
      </div>
      {matrix && <>
        <Collapse in={terminate}>
          <div className={classes.answerRow}>
            <SelectInputField options={matrixKeys} label="Terminate Keys" placeholder="Select Terminate Matrix Keys" optionLabel='option' optionValueKey='option' disabled={disabled} multiple={true} filterSelected={true} value={terminateOptions} onChange={(e, v, k) => handleTerminateOptionsChange(e, v.map(x => x[k]), i)} />
          </div>
        </Collapse>
        {showGoals && (matrixKeys || []).map((option, ix) => (<div key={`matrixoptiongoal${ix}${option.option}`}>
          <div className={classes.answerRow}>
            <Typography className={classes.optionLabel}>{option.option}</Typography>
            <TextField id={`question-matrix-option-goal-text-${i}`} className={classes.timeField} inputProps={{ maxLength: 3, inputMode: "numeric" }} variant="outlined" size="small" placeholder={goalPlaceholder || 'Goal'} disabled={false} value={goal?.[option.option] || ''} onChange={(e) => handleGoalChange(e, i, option)} />
            <Typography className={classes.colon}>%</Typography>
          </div>
        </div>))}
      </>}
    </Root>
  );
}, (prev, next) => prev.answer === next.answer && prev.display === next.display && prev.goal === next.goal && prev.forGroup === next.forGroup && prev.i === next.i && prev.invalid === next.invalid && prev.showGoals === next.showGoals && prev.showTerminate === next.showTerminate && prev.terminate === next.terminate && prev.terminateOptions === next.terminateOptions && prev.matrix === next.matrix && prev.matrixKeys === next.matrixKeys)


const OptionsInput = ({ handleBlur }) => {


  const [optionsList, setOptionsList] = useState("")

  const handleOptionsChange = (e) => {
    handleBlur(optionsList)
    // Clear lists
    setOptionsList("")
  }

  return (
    <Root>
      <div className={classes.answerRow}>
        <TextField id={`question-option-list`} className={[classes.keyField, classes.fullWidthInput].join(" ")} variant="outlined" size="small" multiline={true} minRows={1} maxRows={20} InputProps={{ classes: { inputMultiline: [classes.answerInput, classes.optionBoxHeight].join(" ") } }} placeholder={`Enter list of options`} value={optionsList} onChange={(e) => setOptionsList(e.target.value)} onBlur={(e) => handleOptionsChange(e)} />
      </div>
    </Root>
  )
};

QuestionField.defaultProps = {
  label: 'Segment'
}

export default QuestionField;
