import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, TextField, InputAdornment } from '@mui/material';
const PREFIX = 'TextInputField';

const classes = {
  root: `${PREFIX}-root`,
  subroot: `${PREFIX}-subroot`,
  label: `${PREFIX}-label`,
  sublabel: `${PREFIX}-sublabel`,
  textField: `${PREFIX}-textField`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({label}) => ({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.subroot}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.sublabel}`]: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: label ? 'calc(20% + 16px)' : '10px'
  },
  [`& .${classes.textField}`]: {
    flexBasis: '80%'
    // width: '100%'
  }
}));

const TextInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel }) => {


  const handleChange = useCallback((event) => {
    onChange(null, event.target.value)
  }, [onChange]);

  return (
    (<Root label={label}>
      <div className={classes.root}>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <TextField id={`text-input-field-${label}`} className={classes.textField} inputProps={{ maxLength: maxLength || 524288 }} variant="outlined" size="small" error={invalid} disabled={disabled} value={value || ''} placeholder={placeholder || 'Enter text'} onChange={handleChange} multiline={large} rows={large ? 3 : 1 } InputProps={{
          endAdornment: (
            endComponent && <InputAdornment position="end">
              {typeof endComponent === 'function' ? endComponent() : endComponent}
            </InputAdornment>
          ),
        }}/>
      </div>
      {sublabel && <div className={classes.subroot}>
        <Typography variant="caption" className={classes.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </Root>)
  );
})

// TextInputField.defaultProps = {
//   label: 'Text'
// }

export default TextInputField;
