import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Checkbox } from '@mui/material';
const PREFIX = 'CheckboxField';

const classes = {
    root: `${PREFIX}-root`,
    checkbox: `${PREFIX}-checkbox`,
    label: `${PREFIX}-label`
};

const Root = styled('div')({
    // style for <Root> component
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%',
    [`& .${classes.checkbox}`]: {
        flexBasis: '20%',
        // minWidth: '15%',
        textAlign: 'right',
        marginRight: '10px'
    },
    [`& .${classes.label}`]: {
        flexBasis: '80%'
    }
});

const CheckboxField = React.memo(({ value, label, disabled, placeholder, fieldKey, required, conditional, conditionMet, onChange, invalidate }) => {


    const handleChange = useCallback((event) => {
        event.currentTarget.blur()
        onChange(null, event.target.checked)
    }, [onChange]);

    return (
        <Root>
            <div className={classes.checkbox}>
                <Checkbox id={`checkbox-field-${label}`} size="small" color="primary" checked={value ?? false} disabled={disabled} onChange={handleChange} />
            </div>
            <Typography className={classes.label}>{label}</Typography>
        </Root>
    );
})

CheckboxField.defaultProps = {
    label: 'Text'
}

export default CheckboxField;
